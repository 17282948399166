<template>
<body class="en">
    <div class="wrap">
 <header>
            <h1 class="logo">NDPT</h1>
            <!--1227-->
            <div class="util" style="display: ;">
                <i class="ri-global-line"></i>
                <a href="/">KR</a>
                <a href="/en" class="on">EN</a>
            </div>
    </header>
        <section class="visual">
            <video id="video" muted="" loop="" playsinline="" autoplay="" video-playable-inline="" poster="">
                <source type="video/mp4" src="../assets/img/main.mp4?v=231220">
            </video>
            <div class="inner">
                <h2><span>News Data for Pre-Training</span></h2>
                
                <h1>News data feed service for <br>AI training</h1>
                <h3>AI is here, and NDPT is here to help.</h3>
                <p>Data is what makes AI work.<br>
From defining requirements to building data for AI training,<br>
and providing quality news data with legal usage copyrights.<br>
We will be with you from the beginning to the end of AI development.</p>

                <span class="scroll"></span>
            </div>
        </section>

        <section class="sec1 reveal">
            <div class="inner">
                <div class="sec-tit">NDPT for building successful AI services</div>
                <div class="sec-txt">NDPT provides high-quality news data for AI training.</div>
                
                <div class="sec-box1">
                    <div class="box-li">
                        <div class="img"><img src="../assets/img/sec1-1.jpg"></div>
                        <div class="txt">
                            <div class="box1-num color2">01</div>
                            <div class="box1-tit">Largest collection of news data in Korea</div>
                            <div class="box1-txt">25 years of news data from over 3,000 national media outlets.<br/>
Over 80 years of press photos from 26 major media organizations.<br/>
News data from multiple broadcasters, including the three major terrestrial networks, telecommunications companies and private broadcasters.</div>
                        </div>
                    </div>
                    <div class="box-li">
                        <div class="img"><img src="../assets/img/sec1-2.jpg"></div>
                        <div class="txt">
                            <div class="box1-num color3">02</div>
                            <div class="box1-tit">Once and for all, Copyright Resolution</div>
                            <div class="box1-txt">As an official distributor of the Korea Press Foundation, we have 25 years of experience in the news copyright business with legitimate news data.</div>
                        </div>
                    </div>
                    <div class="box-li">
                        <div class="img"><img src="../assets/img/sec1-3.jpg"></div>
                        <div class="txt">
                            <div class="box1-num color4">03</div>
                            <div class="box1-tit">Retain news data in various formats</div>
                            <div class="box1-txt">Retain text-based newspaper/online articles, press photo images, and broadcast news video data.</div>
                        </div>
                    </div>
                    <div class="box-li">
                        <div class="img"><img src="../assets/img/sec1-4.jpg"></div>
                        <div class="txt">
                            <div class="box1-num color1">04</div>
                            <div class="box1-tit">Provide optimized data for AI training</div>
                            <div class="box1-txt">Provide customized, processed news data for building large language models (LLMs) and large multi modal models (LMMs).
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section class="sec2 reveal">
            <div class="inner">
                <div class="sec-tit">What we have</div>
                <div class="sec-txt">NDPT provides accurate, reliable, and super-sized data.</div>
                
                <div class="count">
                    <div class="counter">{{totalCount}}</div>
                    <div class="count-txt">Total data held as of {{checkDate}}</div>
                </div>
                <div class="sec-box2">
                    <div class="box-li">
                        <div class="tit">Number of News Stories</div>
                        <div class="txt">{{newsNum}}</div>
                        <div class="sum">Average 240,000 per day</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Video Data</div>
                        <div class="txt">{{videoNum}}</div>
                        <div class="sum">Average 1,000 per day</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Press Photos</div>
                        <div class="txt">{{pressPictureNum}}</div>
                        <div class="sum">Average 700 per day</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Press Release Data</div>
                        <div class="txt">{{pressNum}}</div>
                        <div class="sum">Average 200 per day</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Number of Clause</div>
                        <div class="txt">{{clauseNum}}</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Number of Semantic Sentences</div>
                        <div class="txt">{{sentenceNum}}</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Articles generated per day</div>
                        <div class="txt">240K</div>
                    </div>
                    <div class="box-li">
                        <div class="tit">Video Hours</div>
                        <div class="txt">{{videoTimeHour}}<span>hours</span></div>
                    </div>
                </div>
                <div class="sec-box22">
                    <div class="box-top">
                        <div class="tit">Refine/process the source data to provide <br><b>labeling data</b> in the desired format.</div>
                        <a class="btn" href="https://www.scrapmaster.co.kr/price/price.html?loc=media" target="_blank">Check out our partner press
<i class="ri-arrow-right-line"></i></a>
                    </div>
                    <div calss="txt"><img src="../assets/img/sec2-1en.png?v=231226"></div>
                </div>

            </div>
        </section>


        <section class="sec3 reveal">
            <div class="inner">
                <div class="sec-tit">Why NDPT?</div>
                
                <div class="sec-txt">As the AI market expands, the world is in the process of developing AI copyright norms. <br>Data that is not copyrighted is a legal risk.
 </div>

                <div class="sec-box3">
                    <div class="box-li">
                        <a href="https://www.yna.co.kr/view/AKR20231218096500005" target="_blank">
                            <div class="img"><img src="https://img9.yna.co.kr/etc/inner/KR/2023/12/18/AKR20231218096500005_01_i_P4.jpg"></div>
                            <div class="txt">
                                <div class="box-num">연합뉴스</div>
                                <div class="box-tit">신문협회 "생성형 AI 뉴스 학습·활용, 대가 지급 의무화해야"</div>
                            </div>
                        </a>
                    </div>                
                    <div class="box-li">
                        <a href="https://apnews.com/article/openai-chatgpt-associated-press-ap-f86f84c5bcc2f3b98074b38521f5f75a" target="_blank">
                            <div class="img"><img src="https://dims.apnews.com/dims4/default/35e4a2d/2147483647/strip/true/crop/5760x3838+0+1/resize/980x653!/format/webp/quality/90/?url=https%3A%2F%2Fassets.apnews.com%2F56%2F01%2F842934f9317db49d683317b7fbc3%2F3e68df5f764446588ad178914578d268"></div>
                            <div class="txt">
                                <div class="box-num">AP뉴스</div>
                                <div class="box-tit">ChatGPT-maker OpenAI signs deal with AP to license news stories</div>
                            </div>
                        </a>
                    </div>
                    <div class="box-li">
                        <a href="https://www.smarttoday.co.kr/news/articleView.html?idxno=29230" target="_blank">
                            <div class="img"><img src="https://cdn.smarttoday.co.kr/news/photo/202304/29230_22510_387.jpg"></div>
                            <div class="txt">
                                <div class="box-num">스마트투데이</div>
                                <div class="box-tit">생성AI 학습에 공개 데이터 막 써도 되나...소송전 이어질 듯</div>
                            </div>
                        </a>
                    </div>
                    <div class="box-li">
                        <a href="https://www.aitimes.com/news/articleView.html?idxno=152384" target="_blank">
                            <div class="img"><img src="https://cdn.aitimes.com/news/photo/202307/152384_162355_5556.jpg"></div>
                            <div class="txt">
                                <div class="box-num">AI타임스</div>
                                <div class="box-tit">오픈AI 이어 구글도 저작권법 소송..."수억 미국인 인터넷 데이터로 AI 학습"</div>
                            </div>
                        </a>
                    </div>
                </div>

                <div class="box3-tit">NDPT provides secure, copyright-resolved data.</div>
                
            </div>
        </section>



        <section class="sec4 reveal">
            <div class="inner">
                <div class="sec-tit">NDPT Process &amp; Resources</div>
                
                <div class="sec-txt">We provide raw news data that has been refined and processed and <br>labeling data generated through our autolabeling technology.
 </div>

                <div class="sec-box4">
                    <img src="../assets/img/sec4-1en.png?v=231226">
                    <img src="../assets/img/sec4-2en.png?v=231226">
                </div>
            </div>
        </section>


        <section class="sec5 reveal">
            <div class="inner">
                
                <p>NDPT is the only service that provides quality news data, <br>free from copyright issues, has the right data<br/> technology for AI training.
</p>
                <h1>The beginning of AI, <br>NDPT is with you.</h1>

                <div class="we">
                    NDPT<br>
                    
                    is<br>
                    ready!</div>
            </div>
            <video id="video" muted="" loop="" playsinline="" autoplay="" video-playable-inline="" poster="">
                <source type="video/mp4" src="../assets/img/video2.mp4">
            </video>
        </section>



        <section class="sec6 reveal" id="call">
            <div class="inner">
                <div class="sec-tit">Contact Us</div>
                <div class="sec-txt">Please fill out the form below and we'll get back to you as soon as possible.</div>
                <div class="call_box">
                    <dl>
                        <dt>Name</dt>
                        <dd>
                            <input type="text" id="commuName" v-model="name">
                        </dd>
                    </dl>
                    <dl>
                        <dt>Phone Number</dt>
                        <dd>
                            <input type="text" class="num" id="Num" v-model="phone" oninput="this.value = this.value.replace(/[^0-9.]/g, '')">
                        </dd>
                    </dl>
                    <dl>
                        <dt>Company Name</dt>
                        <dd>
                            <input type="text" class="comp" id="comp" v-model="comp">
                        </dd>
                    </dl>
                    <dl>
                        <dt>Email</dt>
                        <dd>
                            <input type="text" class="mail" id="mail" v-model="email" oninput="this.value = this.value.replace(/[^0-9a-zA-Z@.]/g, '');">
                        </dd>
                    </dl>
                    <dl>
                        <dt class="main_cont">Description</dt>
                        <dd class="main_cont">
                            <textarea id="commuContents" v-model="contents"></textarea>
                        </dd>
                    </dl>
                </div>

                <div class="agree_box">
                    <strong>1. Personal Information Collection : Items and Purpose</strong><br>
: Dahami Communications collects the following items for the purpose of enabling the administrator to communicate with the inquirer regarding the questions asked through the inquiry on the NDPT website.<br>
 - Name, Phone Number, Company Name, Email Address<br><br>

   <strong>2. Retention and use period of personal information</strong><br>
: The collected personal information is destroyed immediately after the purpose of retention and use is completed. In addition, if you request deletion through 'Contact Us', it will be destroyed within 3 days.<br><br>

* You may refuse to agree to this. And if you do not agree, we will not be able to send you emails through Ask a Question.
<br>

                   
                </div>
                <div class="agree_check">
                    <p>
                        <input id="agree" type="checkbox" v-model="chkflag">
                        <label for="agree">I agree to the collection and use of personal information.</label>
                    </p>
                </div>
                <div class="call_send" @click="emailSend">
                    <p class="btn">Contact Us</p>
                </div>

            </div>
        </section>
        
        <footer>
            <div class="inner">
                
                <div class="f_top">
                    <div class="txt">Business Registration Number : 112-81-49789 CEO : Yong Lip Park<br>
                        5F, 140, Mareunnae-ro, Jung-gu, Seoul, Korea<br>
                        TEL. 02 593 4174 FAX. 02 593 4175 Email. dahamicommunications@dahami.com</div>
                    <div class="f_logo">
                        <span class="f_logo_img">dahami</span>
                        <span class="f_logo_slogan">All the news in the palm of your hand, <br>Dahami Communications</span>
                    </div>
                </div>
                <div class="f_bottom">
                    <div class="f_copy">Copyright Dahami Communications Inc. All Rights Reserved.</div>
                </div>
                <div class="f_family">
                    <div class="f_tit">Family sites</div>
                    <div class="f_li"><a href="https://www.dahami.com" target="_blank" title="다하미커뮤니케이션즈">다하미커뮤니케이션즈</a></div>
                    <div class="f_li"><a href="http://www.scrapmaster.co.kr" target="_blank" title="스크랩마스터">스크랩마스터</a></div>
                    <div class="f_li"><a href="http://www.clippingon.co.kr" target="_blank" title="클리핑온">클리핑온</a></div>
                    <div class="f_li"><a href="http://www.t-paper.co.kr" target="_blank" title="티페이퍼">티페이퍼</a></div>
                    <div class="f_li"><a href="http://www.newsbank.co.kr" target="_blank" title="뉴스뱅크">뉴스뱅크</a></div>
                    <div class="f_li"><a href="http://news-plaza.com/" target="_blank" title="뉴스플라자">뉴스플라자</a></div>
                </div>
            </div>
        </footer>
    </div>    
    <div class="float">
        <div class="float-li"><a href="#call"><i class="ri-headphone-line"></i></a></div>
        <div class="float-li"><a href="#"><i class="ri-arrow-up-line"></i></a></div>
    </div>
</body>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'NdptEN',
  props: {
    
  },
  data() {
    return {
        // 데이터 개수 카운트용 변수
        totalCount: "",
        checkDate: "",
        newsNum: "",
        newsNumPerDay: "",
        videoNum: "",
        videoNumPerDay: "",
        pressPictureNum: "",
        pressPictureNumPerDay: "",
        pressNum: "",
        pressNumPerDay: "",
        clauseNum: "",
        sentenceNum: "",
        ArticlePerDay: "",
        videoTimeHour: "",        
        // 메일전송용 변수
        name: "",
        phone: "",
        comp: "",
        email: "",
        contents: "", 
        chkflag: "",
    }
  },
  mounted() {    
    this.getNDPTData();

    //-----head 태그 내용 변경하기
    //head 내용 불러오기
    const head = document.head;
    //특정 meta 태그 선택 -> 내용을 영문으로 변경하기.
    const metaDescription = head.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.content = "NDPT is Dahami Communications' news data supply service for AI learning. It provides high-quality news data with legal usage copyrights."
    }
    const metaKeywords = head.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
        metaKeywords.content = "NDPT,endifity,dahami communications,scrapmaster,scrapmaster,LLM,very large language model,LMM,news data,news copyright,news data API,news video,news photo,news article,AI copyright,real-time news,real-time data,large data,data supply,data distribution,data supply service,data labeling,generative AI,learning AI,data for learning,AI training data"
    }

    const ogTitle = head.querySelector('meta[property="og:title"]');
    if (ogTitle) {
        ogTitle.content = "NDPT - The beginning of AI, NDPT is with you."
    }
    const ogDescription = head.querySelector('meta[property="og:description"]');
    if (ogDescription) {
        ogDescription.content = "NDPT is Dahami Communications' news data supply service for AI learning. It provides high-quality news data with legal usage copyrights."
    }

    const metaTwitterTitle = head.querySelector('meta[name="twitter:title"]');
    if (metaTwitterTitle) {
        metaTwitterTitle.content = "NDPT - The beginning of AI, NDPT is with you."
    }
    const metaTwitterDescription = head.querySelector('meta[name="twitter:description"]');
    if (metaTwitterDescription) {
        metaTwitterDescription.content = "NDPT is Dahami Communications' news data supply service for AI learning. It provides high-quality news data with legal usage copyrights."
    }    
  },
  methods: {
    getNDPTData : function() {
        this.$axios.get("https://ndpt.dahami.com/api/dataCount.json")
            .then((res) => {                                
            // 최상단 숫자 카운트
            this.totalCount = res.data.dataCount.articleInfo.totalCount.toLocaleString().toString();              
            // 날짜형식 변환
            const date = new Date(res.data.dataCount.checkDate.toString().substr(0,4) + "-" 
            + res.data.dataCount.checkDate.toString().substr(4,2) + "-" 
            + res.data.dataCount.checkDate.toString().substr(6,2));
            var resdate = new Intl.DateTimeFormat('en-US', {year: 'numeric',month: 'short',day: 'numeric',}).format(date).toString();
            var month = resdate.split(' ')[0];
            var day = resdate.split(' ')[1].split(',')[0];
            var year = resdate.split(' ')[2];
            this.checkDate = day + "/" + month + "/" + year;              
            // 억단위
            this.newsNum = parseInt((res.data.dataCount.articleInfo.totalCount / 1000000) 
            - (res.data.dataCount.articleInfo.totalCount / 1000000 % 100))  + "M+";
            this.videoNum = parseInt(res.data.dataCount.articleInfo.ndptItem[1].totalCount / 10000) / 100+ "M";
            this.pressPictureNum = parseInt(res.data.dataCount.articleInfo.ndptItem[5].totalCount / 10000) / 100 + "M";
            this.pressNum = parseInt(res.data.dataCount.articleInfo.ndptItem[2].totalCount / 1000) + "K";
            this.clauseNum = parseInt((res.data.dataCount.analyzeInfo.clauseCount10000 * 10000) / 100000000) / 10 + "B";
            this.sentenceNum = parseInt((res.data.dataCount.analyzeInfo.sentenceCount10000 * 10000) / 100000000) / 10 + "B";
            this.videoTimeHour = parseInt(res.data.dataCount.analyzeInfo.videoTimeHour / 1000) + "K";	
            
            const observer = new IntersectionObserver((e) => {
                e.forEach(function(element) {
                    if (element.isIntersecting) {            	
                        if(element.target.className.indexOf("count")!=-1) {
                        $(".counter").text('0'); 
                            let countBox = document.querySelector('.counter');
                            let count = 0;
                            let num = res.data.dataCount.articleInfo.totalCount;

                            let counting = setInterval(function () {
                                if (count >= num) {
                                    count = num;
                                    clearInterval(counting);
                                } else {
                                    count += 12345678;
                                }
                                countBox.innerHTML = new Intl.NumberFormat().format(count);
                            }, 10);             		      	
                        }
                    }
                })
            });

            var div = document.querySelectorAll("div");
            div.forEach(function(element) {
                observer.observe(element);
            })     
        });
    },
    emailSend : function() {
        var email_regex =  /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

        if(this.chkflag  == false){
            alert("Please agree to the collection of personal information");
            $('#agree').focus();
        }else if(this.name == ""){
            alert("Please fill in your name");
            $('#commuName').focus();
            return false;
        }else if(this.phone == ""){
            alert("Please fill in your contact information");
            $('#Num').focus();
            return false;
        }else if(this.email == ""){
            alert("Please fill in your e-mail");
            $('#mail').focus();
            return false;
        }else if(this.comp == ""){
            alert("Please fill in the name of the company");
            $('#comp').focus();
            return false;
        }else if(this.contents == ""){
            alert("Please fill in the information");
            $('#commuContents').focus();
            return false;
        }else if(!email_regex.test(this.email)) {
            alert("Email format is not valid. Please re-enter according to format.");
            $('#mail').focus();
            return false;
        }
        else{
            this.$axios.get("/api/sendEmail", { params: {name: this.name,
                phone: this.phone,
                email: this.email,
                comp: this.comp,
                contents: this.contents}})
            .then((res) => {
                console.log(res);
                alert("Your inquiry has been forwarded to the administrator.");
                this.name = "";
                this.phone = "";
                this.comp = "";
                this.email = "";
                this.contents = "";        
                this.chkflag = false;        
            }).catch(error => {
                alert("send mail failed");
                console.log(error.response);
            });
        }        
    }
  },
}

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
      } else {
          reveals[i].classList.remove("active");
      }
  }
}
window.addEventListener("scroll", reveal);
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
